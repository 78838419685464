import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { Label } from '@/components/ui/label.tsx';
import CustomCheckbox from '@/components/custom/CustomCheckbox.tsx';
import Grid from '@/components/custom/Grid.tsx';
import UserProfileCard from '@/components/custom/UserProfileCard.tsx';
import {
	Pagination,
	PaginationContent,
	PaginationItem,
	PaginationNext,
	PaginationPrevious,
} from '@/components/ui/pagination';
import { useGetUsers } from '@/components/api/swrQueries.tsx';
import { GenderEnum } from '@/lib/enum.ts';
import Loader from '@/components/custom/Loader.tsx';
import { useSearchParams } from 'react-router-dom';
import { ISearchUserParams } from '@/lib/interface.ts';
import { useEffect, useState } from 'react';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { ChevronUp } from 'lucide-react';

export default function FindUserPage() {
	const [searchParams, setSearchParams] = useSearchParams();
	const searchPage = searchParams.get('page') as string;
	const searchBanned = searchParams.get('banned') as string;
	const searchGender = searchParams.get('gender') as GenderEnum;
	const searchEmailOrUsername = searchParams.get('emailOrUsername') as string;
	const searchTerm: ISearchUserParams = {
		banned: searchBanned,
		emailOrUsername: searchEmailOrUsername || '',
		page: searchPage ? parseInt(searchPage) : 1,
	};
	if (searchGender) searchTerm.gender = searchGender;
	const { data: users, isLoading: isLoadingUser } = useGetUsers(searchTerm);
	const [genderCheckbox, setGenderCheckbox] = useState({
		MALE: searchTerm.gender === GenderEnum.MALE || !searchTerm.gender,
		FEMALE: searchTerm.gender === GenderEnum.FEMALE || !searchTerm.gender,
	});
	const [bannedCheckbox, setBannedCheckbox] = useState(searchTerm.banned === 'true');
	const [nameOrEmail, setNameOrEmail] = useState(searchTerm.emailOrUsername || '');
	const [page, setPage] = useState(searchTerm.page);
	const [showScrollToTop, setShowScrollToTop] = useState(false);

	useEffect(() => {
		if (!page) return;
		updateSearchParam('page', page.toString());
	}, [page]);

	useEffect(() => {
		const handleScroll = () => {
			setShowScrollToTop(window.scrollY > 500);
		};
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const updateSearchParam = (key: string, value: string) => {
		const params = new URLSearchParams(searchParams);
		params.set(key, value);
		setSearchParams(params, { replace: true });
	};

	const removeSearchParam = (key: string) => {
		const params = new URLSearchParams(searchParams);
		params.delete(key);
		setSearchParams(params, { replace: true });
	};

	const handleBannedChange = (checked: boolean) => {
		setBannedCheckbox(checked);
		updateSearchParam('banned', checked ? 'true' : 'false');
	};

	const search = () => {
		const text = nameOrEmail.trim();
		setNameOrEmail(text);
		updateSearchParam('emailOrUsername', text);
	};

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const handleGenderChange = (gender: GenderEnum, checked: boolean) => {
		const alterGender = gender === GenderEnum.MALE ? GenderEnum.FEMALE : GenderEnum.MALE;
		setGenderCheckbox(prev => {
			return { ...prev, [gender]: checked };
		});
		if (genderCheckbox[alterGender]) {
			if (checked) {
				removeSearchParam('gender');
			} else {
				updateSearchParam('gender', alterGender);
			}
		} else {
			if (checked) {
				updateSearchParam('gender', gender);
			} else {
				removeSearchParam('gender');
			}
		}
	};

	if (!users?.data || isLoadingUser) {
		return <Loader text={'Chargement des utilisateurs'} />;
	}

	return (
		<div className={'p-4 md:container'}>
			<div className={'flex justify-center items-end gap-3'}>
				<div className={'w-full max-w-[500px]'}>
					<Label htmlFor="name">Nom ou Email</Label>
					<Input
						id="name"
						maxLength={30}
						type={'search'}
						value={nameOrEmail}
						placeholder={'Taper ici'}
						onChange={e => {
							setNameOrEmail(e.target.value);
							if (e.target.value === '') {
								removeSearchParam('emailOrUsername');
							}
						}}
						onKeyDown={e => e.key === 'Enter' && search()}
					/>
				</div>
				<Button onClick={search}>Rechercher</Button>
			</div>
			{/* Checkboxes */}
			<div className={'flex justify-center flex-wrap items-center mt-4 mb-6 gap-4'}>
				<CustomCheckbox
					label={'Homme'}
					checked={genderCheckbox.MALE}
					onCheckedChange={checked => handleGenderChange(GenderEnum.MALE, checked)}
				/>
				<CustomCheckbox
					label={'Femme'}
					checked={genderCheckbox.FEMALE}
					onCheckedChange={checked => handleGenderChange(GenderEnum.FEMALE, checked)}
				/>
				<CustomCheckbox
					label={'Banni'}
					checked={bannedCheckbox}
					onCheckedChange={handleBannedChange}
					checkboxClassName={
						'data-[state=checked]:bg-red-500 data-[state=checked]:text-primary-foreground border-red-500'
					}
				/>
			</div>
			{/* Pictures */}
			{users.data?.length === 0 ? (
				<NoDataText text={"Aucun utilisateur n'a été trouvé"} />
			) : (
				<>
					<Grid className={'gap-3 md:gap-8 md:px-32 mb-6'}>
						{users.data?.map((user, i) => (
							<UserProfileCard key={i} item={user} />
						))}
					</Grid>

					{/* Scroll to top button */}
					{showScrollToTop ? (
						<Button
							size="icon"
							onClick={scrollToTop}
							className={'fixed bottom-4 right-4'}
						>
							<ChevronUp className="h-4 w-4" />
						</Button>
					) : null}

					{/* Pagination */}
					<Pagination>
						<PaginationContent>
							<PaginationItem>
								<PaginationPrevious
									onClick={() => setPage(prev => Math.max(prev - 1, 0))}
								/>
							</PaginationItem>
							<PaginationItem>
								<PaginationNext onClick={() => setPage(users.nextPage)} />
							</PaginationItem>
						</PaginationContent>
					</Pagination>
				</>
			)}
		</div>
	);
}
