import { HTMLProps } from 'react';
import { Checkbox } from '@/components/ui/checkbox.tsx';

interface Interface {
	label: string;
	checked: boolean;
	onCheckedChange: (checked: boolean) => void;
	className?: HTMLProps<HTMLElement>['className'];
	checkboxClassName?: HTMLProps<HTMLElement>['className'];
}

export default function CustomCheckbox({
	label,
	checked,
	onCheckedChange,
	className,
	checkboxClassName,
}: Interface) {
	return (
		<div className={`flex items-center space-x-2 cursor-pointer ${className}`}>
			<Checkbox
				id="terms"
				checked={checked}
				className={checkboxClassName}
				onCheckedChange={onCheckedChange}
			/>
			<label
				htmlFor="terms"
				className="text-sm cursor-pointer font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
			>
				{label}
			</label>
		</div>
	);
}
