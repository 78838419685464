import { HTMLProps } from 'react';
import { Badge, BadgeProps } from '@/components/ui/badge.tsx';

interface Interface {
	label: string;
	value: number;
	variant?: BadgeProps['variant'];
	className?: HTMLProps<HTMLElement>['className'];
}

export default function StatBadge({ label, value, variant, className }: Interface) {
	return (
		<Badge variant={variant} className={`h-7 pointer-events-none ${className}`}>
			{label}: {new Intl.NumberFormat('fr-FR').format(value)}
		</Badge>
	);
}
