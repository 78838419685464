import { HTMLProps } from 'react';
import { useParams } from 'react-router-dom';
import Loader from '@/components/custom/Loader.tsx';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { useGetUserSubAccounts } from '@/components/api/swrQueries.tsx';
import ProfileDetailUserData from '@/components/custom/ProfileDetailUserData.tsx';
import { Button } from '@/components/ui/button.tsx';

interface Interface {
	className?: HTMLProps<HTMLElement>['className'];
}

export default function SubAccountsPage({ className }: Interface) {
	const { id } = useParams();
	const { data: usersData, isLoading: isLoadingUsers } = useGetUserSubAccounts(id || '');

	const goToProfile = (id: string) => {
		window.location.href = `/profile/${id}`;
	};

	if (isLoadingUsers)
		return <Loader className={'mt-8'} text={'Chargement des profils'} />;

	if (!usersData || usersData?.length === 0)
		return <NoDataText text={"Cet utilisateur n'a pas de sous-comptes"} />;

	return (
		<div className={`flex flex-col gap-6 md:container ${className}`}>
			{usersData.map((account, index) => {
				return (
					<div
						key={index}
						className={'flex flex-col gap-4 border rounded-md items-center'}
					>
						<ProfileDetailUserData
							listMode
							isModal={false}
							userData={account}
							onTitleClick={() => goToProfile(account._id)}
						/>
						<div className={'-mt-6'}>
							<Button variant={'ghost'} onClick={() => goToProfile(account._id)}>
								Voir le profil
							</Button>
						</div>
					</div>
				);
			})}
		</div>
	);
}
