import { toast } from 'sonner';

export default function useNotification() {
	return (message: string, type: 'success' | 'error' = 'success') => {
		if (type === 'success') {
			toast.success(message);
		} else {
			toast.error(message ?? "Une erreur s'est produite");
		}
	};
}
