import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { IUserModel } from '@/lib/interface.ts';

const userStore = create<IUserModel>()(
	persist(
		set => ({
			user: null,
			setStoreUser: user => set({ user: user }),
		}),
		{
			name: `user-store-${import.meta.env.VITE_APP_ENV.toLowerCase()}`,
		},
	),
);

export default userStore;
