import ProfileDetails from '@/components/custom/ProfileDetails.tsx';
import { useParams } from 'react-router-dom';

export default function ProfileDetailsPage() {
	const { id } = useParams();
	return (
		<div className={'p-4 md:container'}>
			<ProfileDetails userId={id || ''} />
		</div>
	);
}
