import { HTMLProps } from 'react';

interface Interface {
	value?: number;
	isActive?: boolean;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function NumberBadge({ value, isActive = false, className }: Interface) {
	return (
		<div className={className}>
			<span
				className={`inline-flex items-center justify-center ml-2 px-2 py-1 text-xs leading-none rounded-full ${
					isActive ? 'text-white bg-red-500' : 'bg-white text-gray-400'
				}`}
			>
				{value ?? 0}
			</span>
		</div>
	);
}
