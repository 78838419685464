import { HTMLProps } from 'react';

interface Interface {
	label?: string;
	value?: string;
	onClick?: () => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function VerticalHeaderText({
	label,
	value,
	onClick,
	className,
}: Interface) {
	return (
		<div
			onClick={onClick}
			className={`flex flex-col items-center justify-center cursor-pointer ${className}`}
		>
			<h1 className={'text-blue-600 text-sm font-bold'}>{label}</h1>
			<h1 className={'text-sm truncate'}>{value}</h1>
		</div>
	);
}
