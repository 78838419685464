import { HTMLProps } from 'react';
import { getGenderString, getPhotoUrl, isBanned, isCertified } from '@/lib/utils.ts';
import { Card } from '@/components/ui/card.tsx';
import { Button } from '@/components/ui/button.tsx';
import { ISearchUserData } from '@/lib/interface.ts';
import { GenderEnum } from '@/lib/enum.ts';
import { useNavigate } from 'react-router-dom';
import routes from '@/lib/routes.tsx';

interface Interface {
	item: ISearchUserData;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function UserProfileCard({ item, className }: Interface) {
	const navigate = useNavigate();

	return (
		<Card className={`w-[170px] md:w-[190px] relative ${className}`}>
			<img alt={'user-image'} className={'rounded-t-md'} src={getPhotoUrl(item.photo)} />
			{isBanned(item.accountStatus) ? (
				<img
					alt={'user-image'}
					src={'/banned.png'}
					className={'absolute top-0 right-3 w-20'}
				/>
			) : null}
			{isCertified(item.verifications) ? (
				<span className={'absolute top-0 right-2'}>✅</span>
			) : null}

			<div className={'flex flex-col justify-between p-2'}>
				<div className={'flex flex-col justify-between pb-2'}>
					<p className={'font-semibold truncate overflow-hidden'}>{item.username}</p>
					<p className={'text-sm text-gray-500 truncate overflow-hidden'}>{item.email}</p>
					<p
						className={`text-sm font-semibold ${
							item.gender === GenderEnum.MALE ? 'text-blue-500' : 'text-red-500'
						}`}
					>
						{getGenderString(item.gender)}
					</p>
				</div>
				<Button
					size={'sm'}
					className={'w-full text-blue-600 bg-blue-100 hover:bg-blue-200/80'}
					onClick={() => navigate(`${routes.nav.profileDetails}/${item._id}`)}
				>
					Voir
				</Button>
			</div>
		</Card>
	);
}
