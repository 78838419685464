import { HTMLProps } from 'react';
import { Card, CardDescription, CardHeader } from '@/components/ui/card.tsx';
import { IReferralUsers } from '@/lib/interface.ts';
import { getDayMmYear } from '@/lib/utils.ts';
import { GenderEnum } from '@/lib/enum.ts';

interface Interface {
	user: IReferralUsers;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ReferralUserItem({ user, className }: Interface) {
	return (
		<Card
			className={`flex flex-row w-full items-center justify-between p-3 gap-3 ${className}`}
		>
			<CardHeader className={'p-0'}>
				<h1 className={'font-semibold text-purple-600'}>
					{user.gender === GenderEnum.MALE ? '🧔🏽‍♂' : '👩🏽'} {user.username}
				</h1>
				<CardDescription>
					Code de parrainage:{' '}
					<span className={'font-semibold'}>{user.extra?.referralCode}</span>
				</CardDescription>
				<CardDescription>
					Partenaire depuis:{' '}
					<span className={'font-semibold'}>{getDayMmYear(user.createdAt)}</span>
				</CardDescription>
			</CardHeader>
			{/* Code */}
			<Card
				className={
					'text-center p-3 flex flex-col items-center justify-center cursor-pointer'
				}
			>
				<h1 className={'font-semibold text-purple-600'}>{user.referralCount}</h1>
				<CardDescription>Parrainé(s)</CardDescription>
			</Card>
		</Card>
	);
}
