import { HTMLProps } from 'react';
import { isCertified, sortByStatus } from '@/lib/utils.ts';
import PhotoVerificationItemCard from '@/components/custom/PhotoVerificationItemCard.tsx';
import { PhotoGridPositionsEnum } from '@/lib/enum.ts';
import Grid from '@/components/custom/Grid.tsx';
import { IUserPicturesGrid, IUserPicture } from '@/lib/interface.ts';

interface Interface {
	withButtons?: boolean;
	userData: IUserPicturesGrid;
	onClickDeletePictureBtn?: (userId: string, picture: IUserPicture) => void;
	onClickAcceptDenyBtn?: (
		accept: boolean,
		userId: string,
		position: PhotoGridPositionsEnum,
	) => void;
	className?: HTMLProps<HTMLElement>['className'];
	cardClassName?: HTMLProps<HTMLElement>['className'];
}

export default function UserPhotoGrid({
	userData,
	withButtons = true,
	onClickDeletePictureBtn,
	onClickAcceptDenyBtn,
	cardClassName,
	className,
}: Interface) {
	return (
		<Grid className={`gap-4 ${className}`}>
			{isCertified(userData.verifications) && (
				<PhotoVerificationItemCard
					withButtons={withButtons}
					userId={userData._id}
					className={cardClassName}
					picture={{
						position: PhotoGridPositionsEnum.CERTIF,
						path: userData.verifications!.id!.photo,
						status: userData.verifications!.id!.status,
					}}
				/>
			)}
			{userData.pictures?.length > 0 ? (
				userData.pictures.sort(sortByStatus).map((picture, index) => {
					return (
						<PhotoVerificationItemCard
							key={index}
							picture={picture}
							userId={userData._id}
							withButtons={withButtons}
							className={cardClassName}
							onClickAcceptDenyBtn={onClickAcceptDenyBtn}
							onClickDeletePictureBtn={onClickDeletePictureBtn}
						/>
					);
				})
			) : (
				<h1 className={'text-center px-5'}>
					Aucune photo à verifier. Ses photos ont été supprimées car non conformes.
					Veuillez refuser tout!
				</h1>
			)}
		</Grid>
	);
}
