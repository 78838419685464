import { HTMLProps } from 'react';
import routes from '@/lib/routes.tsx';
import Logo from '/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button.tsx';
import userStore from '@/store/userStore.ts';
import useNotification from '@/components/hooks/useNotification.tsx';
import { googleLogout } from '@react-oauth/google';
import { apiClient } from '@/components/api/apiClient.ts';

interface Interface {
	className?: HTMLProps<HTMLElement>['className'];
}

export default function Navbar({ className }: Interface) {
	const showNotification = useNotification();
	const navigate = useNavigate();
	const setStoreUser = userStore(state => state.setStoreUser);

	const logout = () => {
		setStoreUser(null);
		googleLogout();
		navigate(routes.nav.login);
		apiClient.setHeader('Authorization', '');
		showNotification('Vous avez été déconnecté');
	};

	return (
		<header className={`w-full border mb-3 ${className}`}>
			<nav
				className={'md:container mx-2 flex flex-row justify-between items-center my-2'}
			>
				<Link to={routes.nav.home} className="flex flex-row items-center gap-x-2">
					<img src={Logo} alt={'Heyama admin logo'} className={'w-10'} />
					<h2 className="text-xl font-bold">Admin</h2>
				</Link>
				<div>
					<Button
						onClick={logout}
						className={'bg-purple-200 text-purple-700'}
						variant={'secondary'}
					>
						Se déconnecter
					</Button>
				</div>
			</nav>
		</header>
	);
}
