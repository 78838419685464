import { HTMLProps, ReactNode } from 'react';

interface Interface {
	children: ReactNode;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function Grid({ children, className }: Interface) {
	return (
		<div className={`flex flex-wrap items-center justify-center ${className}`}>
			{children}
		</div>
	);
}
