import { HTMLProps } from 'react';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs.tsx';
import NumberBadge from '@/components/custom/NumberBadge.tsx';
import Loader from '@/components/custom/Loader.tsx';

interface Interface {
	value: string;
	isLoading?: boolean;
	onValueChange: (value: any) => void;
	data: { id: string; name: string; count?: number }[];
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ButtonTab({
	data,
	value,
	isLoading,
	onValueChange,
	className,
}: Interface) {
	return (
		<Tabs
			value={value}
			defaultValue={value}
			className={className}
			onValueChange={onValueChange}
		>
			<TabsList>
				{data.map(item => {
					return (
						<TabsTrigger key={item.id} value={item.id}>
							{item.name}{' '}
							{isLoading ? (
								<Loader className={'ml-2'} />
							) : (
								<NumberBadge value={item.count} isActive={value === item.id} />
							)}
						</TabsTrigger>
					);
				})}
			</TabsList>
		</Tabs>
	);
}
