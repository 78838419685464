import CertificationItemCard from '@/components/custom/CertificationItemCard.tsx';
import Grid from '@/components/custom/Grid.tsx';
import ButtonTab from '@/components/custom/ButtonTab.tsx';
import { useEffect, useState } from 'react';
import {
	useCertificationCount,
	useFinishCertification,
	useGetCertifications,
} from '@/components/api/swrQueries.tsx';
import { GenderEnum } from '@/lib/enum.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from '@/components/custom/Loader.tsx';
import { ICertifications } from '@/lib/interface.ts';
import NoDataText from '@/components/custom/NoDataText.tsx';

export default function CertificationsPage() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const searchGender = (searchParams.get('gender') as GenderEnum) || GenderEnum.MALE;
	const [selectedGender, setSelectedGender] = useState(searchGender);
	const { data: certifs, isLoading: isLoadingCertifs } = useGetCertifications({
		gender: searchGender as GenderEnum,
	});
	const { data: certifCount, isLoading: isLoadingCertifCount } = useCertificationCount();
	const { trigger: finishCertification } = useFinishCertification();
	const [genders, setGenders] = useState([
		{ id: GenderEnum.MALE, name: 'Hommes', count: 0 },
		{ id: GenderEnum.FEMALE, name: 'Femmes', count: 0 },
	]);
	const [certifsResCount, setCertifsResCount] = useState<number>(0);
	const [certifications, setCertifications] = useState(certifs || []);

	const onTabChange = (value: GenderEnum) => {
		setSelectedGender(value);
		navigate({ search: `?gender=${value}` }, { replace: true });
	};

	useEffect(() => {
		if (!certifCount) return;
		setGenders(prev => {
			return [
				{ ...prev[0], count: certifCount.male },
				{ ...prev[1], count: certifCount.female },
			];
		});
	}, [certifCount]);

	useEffect(() => {
		if (!searchGender) return;
		onTabChange(searchGender);
	}, [searchGender]);

	useEffect(() => {
		if (!certifs) return;
		setCertifications(certifs);
		setCertifsResCount(certifs.length);
	}, [certifs]);

	useEffect(() => {
		if (!certifications) return;
		/* refresh to get more verifs when list is empty */
		if (certifications.length === 0 && certifsResCount > 0) {
			window.location.reload();
		}
	}, [certifications, certifsResCount]);

	const handleCertification = async (certif: ICertifications, accept: boolean) => {
		window.scrollTo(0, 0);
		/* Remove badge count */
		setGenders(prev => {
			const prevMale = prev[0].count;
			const prevFemale = prev[1].count;
			return [
				{
					...prev[0],
					count: selectedGender === GenderEnum.MALE ? prevMale - 1 : prevMale,
				},
				{
					...prev[1],
					count: selectedGender === GenderEnum.FEMALE ? prevFemale - 1 : prevFemale,
				},
			];
		});
		/* Remove certif from API dont await */
		await finishCertification({
			accept,
			newGender: certif.newGender,
			code: certif.verificationCode,
		});
		/* Remove certif from state */
		setCertifications(prev => prev!.filter(item => item._id !== certif._id));
	};

	return (
		<div className={'md:container mb-4 gap-y-4 flex flex-col items-center'}>
			<ButtonTab
				data={genders}
				value={selectedGender}
				onValueChange={onTabChange}
				isLoading={isLoadingCertifCount}
			/>

			{/* Certifications */}
			<Grid className={'gap-5'}>
				{isLoadingCertifs ? (
					<Loader text={'Chargement des certifications...'} />
				) : certifications?.length > 0 ? (
					certifications?.map((certif, index) => (
						<CertificationItemCard
							key={index}
							item={certif}
							onClick={handleCertification}
						/>
					))
				) : (
					<NoDataText text={'Aucune certification disponible'} />
				)}
			</Grid>
		</div>
	);
}
