import { apiClient } from '@/components/api/apiClient.ts';
import { AdminUpdateActionEnum, BanTypeEnum, GenderEnum } from '@/lib/enum.ts';

export const login = async (
	url: string,
	{ arg }: { arg: { email: string; name: string } },
) => {
	const res = await apiClient.post(url, { adminEmail: arg.email });
	if (res.ok) return { token: res.data as string, name: arg.name };
	else throw res.data;
};

export const deleteProfilePicture = async (
	url: string,
	{ arg }: { arg: { userId: string; position: string } },
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};

export const updateUserProfile = async (
	url: string,
	{ arg }: { arg: { userId: string; action: AdminUpdateActionEnum } },
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};

export const verifyBio = async (
	url: string,
	{ arg }: { arg: { verificationId: string; accept: boolean } },
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};

export const warnUser = async (
	url: string,
	{
		arg,
	}: {
		arg: {
			reportId?: string;
			warnUserId?: string;
			reportedUserId?: string;
			reporterUserId?: string;
		};
	},
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};

export const banUser = async (
	url: string,
	{ arg }: { arg: { userId: string; banType: BanTypeEnum } },
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};

export const toggleMaintenance = async (url: string) => {
	const res = await apiClient.post(url);
	if (!res.ok) throw res.data;
};

export const ignoreReport = async (
	url: string,
	{ arg }: { arg: { userId: string; reportId: string } },
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};

export const finishCertification = async (
	url: string,
	{ arg }: { arg: { code: string; accept: boolean; newGender?: GenderEnum } },
) => {
	const path = arg.accept ? 'accept' : 'deny';
	const res = await apiClient.post(`${url}/${path}`, {
		code: arg.code,
		newGender: arg.newGender,
	});
	if (!res.ok) throw res.data;
};

export const finishVerification = async (
	url: string,
	{ arg }: { arg: { userId: string; denied: string[]; accepted: string[] } },
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};

export const createReferralUser = async (
	url: string,
	{ arg }: { arg: { gender: GenderEnum; username: string; referralCode: string } },
) => {
	const res = await apiClient.post(url, arg);
	if (!res.ok) throw res.data;
};
