import ButtonTab from '@/components/custom/ButtonTab.tsx';
import { useEffect, useState } from 'react';
import { GenderEnum, PhotoGridPositionsEnum, VerifStatusEnum } from '@/lib/enum.ts';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PhotoVerificationGroup from '@/components/custom/PhotoVerificationGroup.tsx';
import {
	useDeleteUserPicture,
	useFinishVerification,
	useGetVerifications,
	useVerificationCount,
} from '@/components/api/swrQueries.tsx';
import Loader from '@/components/custom/Loader.tsx';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { isEmptyString } from '@/lib/utils.ts';
import { IUserPicture, IVerifMakeRequest, IVerifSelection } from '@/lib/interface.ts';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';

export default function VerificationsPage() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const searchGender = (searchParams.get('gender') as GenderEnum) || GenderEnum.MALE;
	const [selectedGender, setSelectedGender] = useState(searchGender);
	const { data: verifs, isLoading: isLoadingVerifs } = useGetVerifications({
		gender: searchGender as GenderEnum,
	});
	const { data: verifCount, isLoading: isLoadingVerifCount } = useVerificationCount();
	const [genders, setGenders] = useState([
		{ id: GenderEnum.MALE, name: 'Hommes', count: 0 },
		{ id: GenderEnum.FEMALE, name: 'Femmes', count: 0 },
	]);
	const { trigger: finishVerification } = useFinishVerification();
	const { trigger: deleteUserPicture } = useDeleteUserPicture();
	const [verifications, setVerifications] = useState(verifs || []);
	const [photosAccepted, setPhotosAccepted] = useState<IVerifSelection>({});
	const [photosDenied, setPhotosDenied] = useState<IVerifSelection>({});
	const [apiCallConfig, setApiCallConfig] = useState<IVerifMakeRequest>({
		canMakeRequest: false,
		userId: undefined,
	});

	const onTabChange = (value: GenderEnum) => {
		setSelectedGender(value);
		navigate({ search: `?gender=${value}` }, { replace: true });
	};

	const onClickDeletePictureBtn = async (userId: string, picture: IUserPicture) => {
		const yes = await confirm(
			'Etes-vous sur de vouloir supprimer cette photo du profil de cet utilisateur?',
		);
		if (!yes) return;
		setVerifications(prevState => {
			return prevState.map(item => {
				const userPics = item.userData.pictures;
				item.userData.pictures = userPics.filter(pic => pic.path !== picture.path);
				return item;
			});
		});
		/* Make api request without waiting res */
		await deleteUserPicture({ userId, position: picture.position });
	};

	const onClickAcceptDenyAllBtn = (acceptAll: boolean, userId: string) => {
		const currentVerif = verifications.find(elm => elm.userId === userId);
		const positions = currentVerif!.urls.map(pic => pic.position);
		if (acceptAll) {
			/* Mark all as ACCEPTED */
			setPhotosAccepted(prev => {
				return { ...prev, [userId]: positions };
			});
		} else {
			/* Mark all as REMOVED */
			setPhotosDenied(prev => {
				return { ...prev, [userId]: positions };
			});
		}
		/* To make sure we have the latest objects */
		setApiCallConfig({ canMakeRequest: true, userId });
	};

	const onClickAcceptDenyBtn = (
		accept: boolean,
		userId: string,
		position: PhotoGridPositionsEnum,
	) => {
		const allVerif = [...verifications];
		if (accept) {
			/* Define what needs to be ACCEPTED */
			setPhotosAccepted((prev: any) => {
				const acc = prev[userId] ?? [];
				acc.push(position);
				return { ...prev, [userId]: acc };
			});
		} else {
			/* Define what needs to be REMOVED */
			setPhotosDenied((prev: any) => {
				const rem = prev[userId] ?? [];
				rem.push(position);
				return { ...prev, [userId]: rem };
			});
		}

		/* Get the current verif object to remove photo from state */
		const currentVerif = allVerif.find(elm => elm.userId === userId);
		const newUrls = currentVerif!.userData.pictures.filter(
			photo => photo.position !== position,
		);

		/* Apply the changes to state */
		setVerifications(prev => {
			prev.map(verif => {
				if (verif.userId === userId) verif.userData.pictures = newUrls;
			});
			return [...prev];
		});

		if (newUrls.filter(item => item.status === VerifStatusEnum.PENDING).length === 0) {
			/* We have no pics left, make request */
			/* To make sure we have the latest objects */
			setApiCallConfig({ canMakeRequest: true, userId });
		}
	};

	const handleVerification = async (
		userId: string,
		photosAccepted: IVerifSelection,
		photosDenied: IVerifSelection,
	) => {
		window.scrollTo(0, 0);
		/* Remove the verif group in state */
		setVerifications(prev => {
			return prev.filter(item => item.userId !== userId);
		});
		/* Remove badge count */
		setGenders(prev => {
			const prevMale = prev[0].count;
			const prevFemale = prev[1].count;
			return [
				{
					...prev[0],
					count: selectedGender === GenderEnum.MALE ? prevMale - 1 : prevMale,
				},
				{
					...prev[1],
					count: selectedGender === GenderEnum.FEMALE ? prevFemale - 1 : prevFemale,
				},
			];
		});

		/* Make api request */
		await finishVerification({
			userId,
			denied: photosDenied[userId],
			accepted: photosAccepted[userId],
		});
		setApiCallConfig((prev: any) => {
			return { ...prev, canMakeRequest: undefined };
		});
	};

	useEffect(() => {
		if (
			verifications.length === 0 &&
			!apiCallConfig.canMakeRequest &&
			apiCallConfig.userId
		) {
			window.location.reload();
		}
	}, [verifications, apiCallConfig]);

	useEffect(() => {
		if (apiCallConfig.canMakeRequest && !isEmptyString(apiCallConfig.userId)) {
			handleVerification(apiCallConfig.userId!, photosAccepted, photosDenied);
		}
	}, [apiCallConfig, photosAccepted, photosDenied]);

	useEffect(() => {
		if (!verifCount) return;
		setGenders(prev => {
			return [
				{ ...prev[0], count: verifCount.male },
				{ ...prev[1], count: verifCount.female },
			];
		});
	}, [verifCount]);

	useEffect(() => {
		if (!verifs) return;
		setVerifications(verifs);
	}, [verifs]);

	useEffect(() => {
		if (!searchGender) return;
		onTabChange(searchGender);
	}, [searchGender]);

	return (
		<div className={'md:container mb-4 gap-y-4 flex flex-col items-center'}>
			<ButtonTab
				data={genders}
				value={selectedGender}
				onValueChange={onTabChange}
				isLoading={isLoadingVerifCount}
			/>

			<div>
				{isLoadingVerifs ? (
					<Loader text={'Chargement des verifications...'} />
				) : (
					<div className={'flex flex-col gap-y-4'}>
						{verifications.length > 0 ? (
							verifications.map((item, index) => {
								return (
									<PhotoVerificationGroup
										key={index}
										item={item}
										onClickAcceptDenyBtn={onClickAcceptDenyBtn}
										onClickDeletePictureBtn={onClickDeletePictureBtn}
										onClickAcceptDenyAllBtn={onClickAcceptDenyAllBtn}
									/>
								);
							})
						) : (
							<NoDataText text={'Aucune verification disponible'} />
						)}
					</div>
				)}
			</div>
		</div>
	);
}
