import { ReactNode } from 'react';
import userStore from '@/store/userStore.ts';
import { Navigate } from 'react-router-dom';
import routes from '@/lib/routes.tsx';

interface Interface {
	page: ReactNode;
}

export default function PrivateRoute({ page }: Interface) {
	const user = userStore(state => state.user);
	return user ? page : <Navigate to={routes.nav.login} />;
}
