import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog.tsx';
import { Button } from '@/components/ui/button.tsx';

interface Interface {
	show: boolean;
	confirmation: string;
	proceed: (val: boolean) => void;
}
export const Confirmation = ({ show, confirmation, proceed }: Interface) => {
	return (
		<Dialog open={show} onOpenChange={() => proceed(false)}>
			<DialogContent className={'p-4'}>
				<DialogHeader>
					<DialogTitle>Attention</DialogTitle>
					<DialogDescription>{confirmation}</DialogDescription>
				</DialogHeader>

				<DialogFooter className={'flex flex-row justify-center gap-2'}>
					<Button
						className={'min-w-[100px]'}
						onClick={() => proceed(false)}
						variant={'destructive'}
					>
						Non
					</Button>
					<Button className={'min-w-[100px]'} onClick={() => proceed(true)}>
						Oui
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};
