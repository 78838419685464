import {
	useBanUser,
	useGetReports,
	useIgnoreReport,
	useWarnUser,
} from '@/components/api/swrQueries.tsx';
import { useEffect, useState } from 'react';
import { IReport } from '@/lib/interface.ts';
import ReportItem from '@/components/custom/ReportItem.tsx';
import Grid from '@/components/custom/Grid.tsx';
import Loader from '@/components/custom/Loader.tsx';
import NoDataText from '@/components/custom/NoDataText.tsx';
import { BanTypeEnum } from '@/lib/enum.ts';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';

export default function ReportsPage() {
	const {
		mutate: getReports,
		data: reportVerifs,
		isLoading: isLoadingReports,
	} = useGetReports();
	const { trigger: warnUser } = useWarnUser();
	const { trigger: ignoreReport } = useIgnoreReport();
	const { trigger: banUser } = useBanUser();
	const [reports, setReports] = useState<IReport[]>([]);

	useEffect(() => {
		if (!reportVerifs) return;
		setReports(reportVerifs);
	}, [reportVerifs]);

	const removeReportFromState = (reportId: string) => {
		setReports(prev => prev.filter(verif => verif._id !== reportId));
	};

	const onWarnReporter = async (reporterUserId: string, reportId: string) => {
		const yes = await confirm('Etes-vous sur de vouloir avertir cet utilisateur ?');
		if (!yes) return;
		await warnUser({ reporterUserId, reportId });
		await getReports();
	};

	const onWarnReported = async (reportedUserId: string, reportId: string) => {
		const yes = await confirm('Etes-vous sur de vouloir avertir cet utilisateur ?');
		if (!yes) return;
		removeReportFromState(reportId);
		await warnUser({ reportedUserId, reportId });
	};

	const onIgnoreReport = async (reportedUserId: string, reportId: string) => {
		const yes = await confirm('Etes-vous sur de vouloir ignorer ce signalement ?');
		if (!yes) return;
		removeReportFromState(reportId);
		await ignoreReport({ userId: reportedUserId, reportId });
	};

	const onBanReportedUser = async (
		reportedUserId: string,
		banType: BanTypeEnum,
		reportId: string,
	) => {
		const yes = await confirm('Etes-vous sur de vouloir bannir cet utilisateur ?');
		if (!yes) return;
		removeReportFromState(reportId);
		await banUser({ userId: reportedUserId, banType });
	};

	return (
		<div className={'md:container mb-4 gap-y-4 flex flex-col items-center'}>
			{/* Reports */}
			<Grid className={'gap-5 w-full'}>
				{isLoadingReports ? (
					<Loader text={'Chargement des signalements...'} />
				) : reports.length > 0 ? (
					reports.map((report, index) => (
						<ReportItem
							key={index}
							item={report}
							onIgnoreReport={onIgnoreReport}
							onWarnReporter={onWarnReporter}
							onWarnReported={onWarnReported}
							onBanReportedUser={onBanReportedUser}
						/>
					))
				) : (
					<NoDataText text={'Aucun signalement disponible'} />
				)}
			</Grid>
		</div>
	);
}
