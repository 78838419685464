import { HTMLProps, MouseEventHandler, ReactNode } from 'react';
import { Card, CardHeader } from '@/components/ui/card.tsx';
import StatBadge from '@/components/custom/StatBadge.tsx';
import Loader from '@/components/custom/Loader.tsx';

import { isNull } from '@/lib/utils.ts';

interface Interface {
	label: string;
	isLoading?: boolean;
	icon: ReactNode;
	value?: number;
	onClick?: MouseEventHandler<HTMLDivElement>;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function HomeCard({
	label,
	value,
	icon,
	onClick,
	isLoading,
	className,
}: Interface) {
	return (
		<Card
			onClick={onClick}
			className={`max-w-45 cursor-pointer hover:opacity-45 ${className} `}
		>
			<CardHeader className={'items-center'}>
				{icon}
				<h1 className={'text-center'}>{label}</h1>
				{isLoading ? (
					<Loader />
				) : (
					<StatBadge
						value={value || 0}
						variant={'secondary'}
						label={'En attente'}
						className={`${isNull(value) && 'invisible'}`}
					/>
				)}
			</CardHeader>
		</Card>
	);
}
