import { HTMLProps, useEffect, useState } from 'react';
import { getPhotoUrl } from '@/lib/utils.ts';
import Grid from '@/components/custom/Grid.tsx';
import { Button } from '@/components/ui/button.tsx';
import { IUserPicture } from '@/lib/interface.ts';
import { useDeleteUserPicture } from '@/components/api/swrQueries.tsx';
import Loader from '@/components/custom/Loader.tsx';
import { VerifStatusEnum } from '@/lib/enum.ts';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';

interface Interface {
	userId: string;
	certifPhoto?: string;
	pictures: IUserPicture[];
	showUnverifiedPictures?: boolean;
	className?: HTMLProps<HTMLElement>['className'];
	imageContainerClassName?: HTMLProps<HTMLElement>['className'];
}

export default function ProfilePictureItemGrid({
	userId,
	pictures,
	certifPhoto,
	imageContainerClassName,
	showUnverifiedPictures = false,
	className,
}: Interface) {
	const [userPictures, setUserPictures] = useState<IUserPicture[]>([]);
	const { trigger: deleteUserPicture, isMutating: isDeleting } = useDeleteUserPicture();

	useEffect(() => {
		if (!pictures) return;
		setUserPictures(pictures);
	}, [pictures]);

	const deletePicture = async (pic: IUserPicture) => {
		const yes = await confirm('Etes-vous sur de vouloir supprimer ?');
		if (!yes) return;
		setUserPictures(prevState =>
			prevState.filter(item => item.position !== pic.position),
		);
		await deleteUserPicture({ userId, position: pic.position });
	};

	if (userPictures.length === 0) return null;

	return (
		<Grid className={`gap-2 md:gap-5 px-2 ${className}`}>
			{certifPhoto && (
				<div className={`w-28 md:w-36 relative ${imageContainerClassName}`}>
					<img
						alt={'user-image'}
						className={'rounded-md'}
						src={getPhotoUrl(certifPhoto)}
					/>
					<div className={'h-8 mt-1.5'} />
				</div>
			)}
			{userPictures.map((pic, index) =>
				/* If a user pic is to be verified, don't show it in the grid */
				pic.status !== VerifStatusEnum.PENDING || showUnverifiedPictures ? (
					<div key={index} className={`w-28 md:w-36 relative ${imageContainerClassName}`}>
						<div className={'relative'}>
							<img
								alt={'user-image'}
								className={'rounded-md'}
								src={getPhotoUrl(pic.path)}
							/>
							{/* Verified images appear with a checkmark */}
							{pic.status !== VerifStatusEnum.PENDING ? (
								pic.verifiedBy ? (
									<div className={'absolute bottom-1 left-1 right-1'}>
										<div
											className={
												'bg-white text-xs rounded overflow-hidden truncate p-0.5'
											}
										>
											☑️ par: {pic.verifiedBy?.username}
										</div>
									</div>
								) : (
									<span className={'absolute top-0.5 right-0.5 shadow-lg'}>☑️</span>
								)
							) : null}
						</div>
						<Button
							disabled={isDeleting}
							onClick={() => deletePicture(pic)}
							className={
								'w-full text-sm h-8 text-red-500 bg-red-200 hover:bg-red-100 mt-1.5'
							}
						>
							{isDeleting ? <Loader /> : 'Supprimer'}
						</Button>
					</div>
				) : null,
			)}
		</Grid>
	);
}
