import { HTMLProps } from 'react';
import { Card, CardContent, CardFooter } from '@/components/ui/card.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import VerticalHeaderText from '@/components/custom/VerticalHeaderText.tsx';
import { IUserPicture, IVerifications } from '@/lib/interface.ts';
import { Button } from '@/components/ui/button.tsx';
import { getGenderString, getTimeSince, getUserCoinCount } from '@/lib/utils.ts';
import { PhotoGridPositionsEnum } from '@/lib/enum.ts';
import UserPhotoGrid from '@/components/custom/UserPhotoGrid.tsx';
import routes from '@/lib/routes.tsx';
import { useNavigate } from 'react-router-dom';
import ProfileBottomStats from '@/components/custom/ProfileBottomStats..tsx';

interface Interface {
	item: IVerifications;
	className?: HTMLProps<HTMLElement>['className'];
	onClickAcceptDenyBtn?: (
		accept: boolean,
		userId: string,
		position: PhotoGridPositionsEnum,
	) => void;
	onClickDeletePictureBtn?: (userId: string, picture: IUserPicture) => void;
	onClickAcceptDenyAllBtn?: (acceptAll: boolean, userId: string) => void;
}

export default function PhotoVerificationGroup({
	item,
	className,
	onClickAcceptDenyBtn,
	onClickDeletePictureBtn,
	onClickAcceptDenyAllBtn,
}: Interface) {
	const navigate = useNavigate();
	const showProfileDetails = (userId: string) => {
		navigate(`${routes.nav.profileDetails}/${userId}`);
	};
	const coins = getUserCoinCount(
		item.userData?.coins,
		item.userData.diamondWallet?.coins,
	);

	return (
		<Card className={`mx-2 ${className}`}>
			<div className={'flex flex-row items-center justify-center px-6 py-2 gap-x-6'}>
				<VerticalHeaderText
					label={'Nom'}
					value={item.userData.username}
					onClick={() => showProfileDetails(item.userData._id)}
				/>
				<VerticalHeaderText
					label={'Ville'}
					value={item.userData?.cityData?.nameFR}
					onClick={() => showProfileDetails(item.userData._id)}
				/>
				<VerticalHeaderText
					label={'Sexe'}
					value={getGenderString(item.userData.gender)}
					onClick={() => showProfileDetails(item.userData._id)}
				/>
				<VerticalHeaderText
					label={'Pièces'}
					value={`${coins.toLocaleString()}🪙`}
					onClick={() => showProfileDetails(item.userData._id)}
				/>
			</div>
			<Separator />
			<CardContent className={'flex flex-col justify-center py-4 px-2 md:px-4'}>
				<UserPhotoGrid
					className={'pb-4'}
					userData={item.userData}
					onClickAcceptDenyBtn={onClickAcceptDenyBtn}
					onClickDeletePictureBtn={onClickDeletePictureBtn}
				/>
				<ProfileBottomStats
					hideTitle={true}
					className={'mb-2'}
					userId={item?.userData._id}
					deviceBanCount={item?.userData?.deviceBanCount}
					deviceAccountsCount={item?.userData?.deviceAccountsCount}
				/>
				<span className={'text-center text-sm text-gray-500'}>
					{getTimeSince(item.createdAt, 'Vérification demandée')}
				</span>
			</CardContent>
			<Separator />
			<CardFooter
				className={'flex flex-row items-center justify-center md:justify-end p-4'}
			>
				<div className={'flex flex-row justify-center items-center gap-x-4'}>
					<Button
						className={'w-36'}
						variant={'destructive'}
						onClick={() => onClickAcceptDenyAllBtn?.(false, item.userData._id)}
					>
						Refuser tout
					</Button>
					<Button
						className={'w-36'}
						variant={'default'}
						onClick={() => onClickAcceptDenyAllBtn?.(true, item.userData._id)}
					>
						Accepter tout
					</Button>
				</div>
			</CardFooter>
		</Card>
	);
}
