import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { Toaster } from 'sonner';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SWRConfig } from 'swr';
import fetcher from '@/components/api/fetcher.ts';
import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
	environment: import.meta.env.VITE_APP_ENV,
	enabled: import.meta.env.VITE_APP_ENV === 'production',
	integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<SWRConfig value={{ fetcher }}>
			<Toaster
				richColors
				duration={2000}
				visibleToasts={1}
				closeButton={true}
				position="top-right"
			/>
			<GoogleOAuthProvider clientId={import.meta.env.VITE_GC_CLIENT_ID}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</GoogleOAuthProvider>
		</SWRConfig>
	</React.StrictMode>,
);
