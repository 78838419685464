import { Label } from '@/components/ui/label.tsx';
import { Switch } from '@/components/ui/switch.tsx';
import { HTMLProps } from 'react';

interface Interface {
	label: string;
	color?: string;
	checked: boolean;
	onCheckedChange: (value: boolean) => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function CustomSwitch({
	label,
	checked,
	onCheckedChange,
	color = 'bg-black',
	className,
}: Interface) {
	return (
		<div className={`flex items-center space-x-2 ${className}`}>
			<Switch
				id={label}
				checked={checked}
				className={`data-[state=checked]:${color}`}
				onCheckedChange={onCheckedChange}
			/>
			<Label htmlFor={label}>{label}</Label>
		</div>
	);
}
