import { Card, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import HomeCard from '@/components/custom/HomeCard.tsx';
import {
	BadgeCheck,
	BarChartBig,
	Image,
	MessageCircleWarning,
	ScanBarcode,
	Text,
	UserRoundSearch,
} from 'lucide-react';
import userStore from '@/store/userStore.ts';
import {
	useDashboardData,
	useMaintenance,
	useToggleMaintenance,
} from '@/components/api/swrQueries.tsx';
import routes from '@/lib/routes.tsx';
import { Link, useNavigate } from 'react-router-dom';
import { GenderEnum } from '@/lib/enum.ts';
import CustomSwitch from '@/components/custom/Switch.tsx';
import { useEffect, useState } from 'react';
import { confirm } from '@/components/custom/react-confirm/Confirm.tsx';

export default function HomePage() {
	const navigate = useNavigate();
	const user = userStore(state => state.user);
	const { data: maintenanceState } = useMaintenance();
	const { trigger: toggleMaintenance } = useToggleMaintenance();
	const { data: cardsData, isLoading: cardDataLoading } = useDashboardData();
	const [isMaintenanceActive, setIsMaintenanceActive] = useState(false);

	useEffect(() => {
		if (typeof maintenanceState === 'boolean') {
			setIsMaintenanceActive(maintenanceState);
		}
	}, [maintenanceState]);

	const navTo = (route: string) => {
		navigate(route);
	};

	const handleToggleMaintenance = async (checked: boolean) => {
		const yes = await confirm(
			checked ? 'Activer la maintenance ?' : 'Désactiver la maintenance ?',
		);
		if (!yes) return;
		setIsMaintenanceActive(checked);
		/* Make the request */
		await toggleMaintenance();
	};

	return (
		<div className={'md:container mx-3 mb-3'}>
			{/* Top Card */}
			<Card>
				<CardHeader className={'items-center'}>
					<CardTitle>Bienvenue {user?.name}</CardTitle>
					<CardDescription className={'text-center'}>
						Réglez les vérifications le plus rapidement possible. N'attendez pas qu'il y
						en ai plusieurs pour commencer.
					</CardDescription>
					<CustomSwitch
						className={'pt-3'}
						color={'bg-red-500'}
						checked={isMaintenanceActive}
						onCheckedChange={handleToggleMaintenance}
						label={isMaintenanceActive ? 'Maintenance en cours' : 'Système opérationnel'}
					/>
				</CardHeader>
			</Card>
			{/* Tiles */}
			<div
				className={'grid grid-cols-2 md:grid-cols-4 gap-3 md:gap-8 mt-3 md:mt-8 lg:mx-20'}
			>
				{/* Card */}
				<HomeCard
					value={cardsData?.id}
					label={'Certifications'}
					isLoading={cardDataLoading}
					icon={<BadgeCheck size={60} strokeWidth={1} />}
					onClick={() => navTo(`${routes.nav.certifications}?gender=${GenderEnum.MALE}`)}
				/>
				<HomeCard
					label={'Photos'}
					value={cardsData?.photo}
					isLoading={cardDataLoading}
					icon={<Image size={60} strokeWidth={1} />}
					onClick={() => navTo(`${routes.nav.verifications}?gender=${GenderEnum.MALE}`)}
				/>
				<HomeCard
					label={'Bio'}
					value={cardsData?.bio}
					isLoading={cardDataLoading}
					icon={<Text size={60} strokeWidth={1} />}
					onClick={() => navTo(routes.nav.bioReview)}
				/>
				<HomeCard
					label={'Signalements'}
					value={cardsData?.reports}
					isLoading={cardDataLoading}
					onClick={() => navTo(routes.nav.reports)}
					icon={<MessageCircleWarning size={60} strokeWidth={1} />}
				/>
				<Link to={routes.links.stats} target={'_blank'}>
					<HomeCard
						label={'Statistiques'}
						icon={<BarChartBig size={60} strokeWidth={1} />}
					/>
				</Link>
				<HomeCard
					label={'Utilisateurs'}
					onClick={() => navTo(routes.nav.findUser)}
					icon={<UserRoundSearch size={60} strokeWidth={1} />}
				/>
				<HomeCard
					label={'Parrainage'}
					onClick={() => navTo(routes.nav.referral)}
					icon={<ScanBarcode size={60} strokeWidth={1} />}
				/>
			</div>
		</div>
	);
}
