import { HTMLProps } from 'react';
import { Button } from '@/components/ui/button.tsx';
import { Card } from '@/components/ui/card.tsx';
import { IReporter } from '@/lib/interface.ts';
import { getCertificationBadge, getGenderString } from '@/lib/utils.ts';

interface Interface {
	item: IReporter;
	onClickName: (userId: string) => void;
	onWarnUserClick: () => void;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function ReporterCardItem({
	item,
	onClickName,
	onWarnUserClick,
	className,
}: Interface) {
	return (
		<Card
			className={`flex flex-col md:flex-row md:items-end md:justify-between p-3 gap-2 ${className}`}
		>
			<div className={'flex flex-col gap-2 md:gap-0'}>
				<span
					onClick={() => onClickName(item.reporter)}
					className={'text-sm text-gray-400 cursor-pointer'}
				>
					{getCertificationBadge(item.verifications)} {item.username} •{' '}
					{getGenderString(item.gender)} • {item.reason}{' '}
					{item.reportAbuseWarningSent &&
						`• Avertissements: ${item.reportAbuseWarningSent}`}
				</span>
				<p className={'text-sm'}>{item.description}</p>
			</div>
			<div className={'flex flex-row justify-end items-end w-full md:w-auto'}>
				<Button
					size={'sm'}
					variant={'secondary'}
					onClick={onWarnUserClick}
					className={'w-full text-orange-500 bg-orange-100 hover:bg-orange-200/80'}
				>
					Avertir
				</Button>
			</div>
		</Card>
	);
}
