import { HTMLProps } from 'react';
import { Loader2 } from 'lucide-react';
import { isEmptyString } from '@/lib/utils.ts';

interface Interface {
	text?: string;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function Loader({ text, className }: Interface) {
	return (
		<div className={`flex flex-col items-center gap-2 ${className}`}>
			<Loader2 className={`animate-spin ${isEmptyString(text) ? 'ml-2' : ''}`} />
			{text ? <span className={'font-semibold text-md'}>{text}</span> : null}
		</div>
	);
}
