import { HTMLProps } from 'react';

interface Interface {
	text: string;
	className?: HTMLProps<HTMLElement>['className'];
}

export default function NoDataText({ text, className }: Interface) {
	return (
		<span className={`flex font-semibold justify-center text-md py-4 ${className}`}>
			{text}
		</span>
	);
}
