import { HTMLProps } from 'react';
import { IUserPicture } from '@/lib/interface.ts';
import { PhotoGridPositionsEnum, VerifStatusEnum } from '@/lib/enum.ts';
import { Button } from '@/components/ui/button.tsx';
import { getPhotoUrl } from '@/lib/utils.ts';
import { Card } from '@/components/ui/card.tsx';

interface Interface {
	userId: string;
	withButtons?: boolean;
	picture: IUserPicture;
	onClickDeletePictureBtn?: (userId: string, picture: IUserPicture) => void;
	onClickAcceptDenyBtn?: (
		accept: boolean,
		userId: string,
		position: PhotoGridPositionsEnum,
	) => void;
	className?: HTMLProps<HTMLElement>['className'];
}

const btnContainer =
	'flex flex-row flex-wrap md:flex-nowrap justify-center items-center my-2 px-3 gap-2';
export default function PhotoVerificationItemCard({
	picture,
	userId,
	className,
	withButtons = true,
	onClickDeletePictureBtn,
	onClickAcceptDenyBtn,
}: Interface) {
	return (
		<Card className={`w-[170px] md:w-[180px] self-start ${className}`}>
			<img
				alt={'user photo'}
				src={getPhotoUrl(picture.path)}
				className={`w-full h-full ${
					withButtons ? 'rounded-t-md' : 'rounded-md'
				} object-contain`}
			/>

			{/* Buttons */}
			{picture.position === PhotoGridPositionsEnum.CERTIF ? null : picture.status ===
			  VerifStatusEnum.PENDING ? (
				onClickAcceptDenyBtn ? (
					<div className={btnContainer}>
						<Button
							size={'sm'}
							variant={'default'}
							className={'w-full text-red-500 bg-red-100 hover:bg-red-200/80'}
							onClick={() => onClickAcceptDenyBtn?.(false, userId, picture.position)}
						>
							Refuser
						</Button>
						<Button
							size={'sm'}
							variant={'default'}
							className={'w-full text-green-600 bg-green-100 hover:bg-green-200/80'}
							onClick={() => onClickAcceptDenyBtn?.(true, userId, picture.position)}
						>
							Accepter
						</Button>
					</div>
				) : null
			) : onClickDeletePictureBtn ? (
				<div className={btnContainer}>
					<Button
						size={'sm'}
						variant={'ghost'}
						className={'w-full text-red-500 hover:text-red-500 hover:bg-red-200/80'}
						onClick={() => onClickDeletePictureBtn?.(userId, picture)}
					>
						Supprimer
					</Button>
				</div>
			) : null}
		</Card>
	);
}
